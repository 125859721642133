import { ArrowLeft, ArrowRight } from 'iconsax-react';
import React from 'react';

interface PaginationProps {
	totalItems: number;
	itemsPerPage: number;
	currentPage: number;
	onPageChange: (page: number) => void;
}

const Pagination: React.FC<PaginationProps> = ({ totalItems, itemsPerPage, currentPage, onPageChange }) => {
	const totalPages = Math.ceil(totalItems / itemsPerPage);

	const handlePageChange = (page: number) => {
		if (page < 1 || page > totalPages) return;
		onPageChange(page);
	};

	return (
		<div className="pagination">
			<button
				disabled={currentPage === 1}
				onClick={() => handlePageChange(currentPage - 1)}
			>
				<ArrowLeft
                    size="32"
                    color="#24CA6A"
                />
			</button>

			{Array.from({ length: totalPages }, (_, index) => (
				<button
					key={index}
					onClick={() => handlePageChange(index + 1)}
					className={currentPage === index + 1 ? 'active' : ''}
				>
					{index + 1}
				</button>
			))}

			<button
				disabled={currentPage === totalPages}
				onClick={() => handlePageChange(currentPage + 1)}
			>
				<ArrowRight
                    size="32"
                    color="#24CA6A"
                />
			</button>
		</div>
	);
};

export default Pagination;
