import React, { createContext, useContext, useState, ReactNode, Dispatch, SetStateAction } from 'react';

// Define the context type
interface AudioContextState {
    setIsPlaying: Dispatch<SetStateAction<boolean>>;
    isPlaying: boolean;
    currentTrack: any; // Define as needed
    setCurrentTrack: (track: any) => void;
    setActiveAudioRef: (audio: any) => void;
    stopCurrentAudio: () => void;
    progress: number;
    currentTime: number;
    duration: number;
    togglePlayPause: () => void;
    handlePrevTrack: () => void;  // Add playNext function
    handleNextTrack: () => void;  // Add playPrevious function
    setProgress: (progress: number) => void;  // Add setProgress function
}

// Create context
const AudioContext = createContext<AudioContextState | undefined>(undefined);

// Provider component
export const AudioProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTrack, setCurrentTrack] = useState<any>(null);  // Replace with correct track type
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0); // Track index for the current track
    const [progress, setProgressState] = useState<number>(0);
    const [currentTime, setCurrentTime] = useState<number>(0);
    const [duration, setDuration] = useState<number>(0);
    const [activeAudioRef, setActiveAudioRef] = useState<HTMLAudioElement | null>(null);


    const handlePrevTrack = () => {
        if (currentTrackIndex > 0) {
            setCurrentTrackIndex((prevIndex) => prevIndex - 1);
        }
    };

    const handleNextTrack = () => {
        // if (currentTrackIndex < (Array.isArray(audioSrc) ? audioSrc.length - 1 : 0)) {
        //     setCurrentTrackIndex((prevIndex) => prevIndex + 1);
        // }
        // if(audioRef.current){
        //     audioRef.current.play().catch((error) => {
        //         console.error("Failed to play audio:", error);
        //     });
        // }
    };

    // Function to stop currently playing audio (if any)
    const stopCurrentAudio = () => {
        if (activeAudioRef && !activeAudioRef.paused) {
            activeAudioRef.pause();
        }
    };
    
    const togglePlayPause = () => {
        setIsPlaying((prev) => !prev);
    };

    const playNext = () => {
        // Logic for playing next track
    };

    const playPrevious = () => {
        // Logic for playing previous track
    };

    const setProgress = (newProgress: number) => {
        setProgressState(newProgress);
        // Additional logic to seek the track based on the new progress
    };

    return (
        <AudioContext.Provider value={{
            isPlaying,
            setIsPlaying,  
            currentTrack,
            setCurrentTrack,
            progress,
            currentTime,
            duration,
            togglePlayPause,
            handleNextTrack,
            handlePrevTrack,
            setProgress,
            stopCurrentAudio,
            setActiveAudioRef,
        }}>
            {children}
        </AudioContext.Provider>
    );
};

// Hook to use the Audio context
export const useAudio = () => {
    const context = useContext(AudioContext);
    if (context === undefined) {
        throw new Error('useAudio must be used within an AudioProvider');
    }
    return context;
};
