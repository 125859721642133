import React from 'react'
import { PiBookBookmarkThin } from "react-icons/pi";
import tfm from '../../images/BOOK DESIGN FISH.png'
import glossa from '../../images/BOOK DESIGN glosa b.png'
import basics from '../../images/BOOK DESIN basics.png'
import BookCard from './BookCard'
import books from '../../utils/Books'
import ButtonX from '../ButtonX'
import BookCarousel from './BookCarousel';


const RecentBooks = () => {

    return (
        <div className="flex items-center flex-col py-[6%] px-[7%] bg-navyBlue" id='books'>
            <div className="flex flex-col items-center">
                <div className="flex sm:flex-row flex-col justify-center items-center gap-x-2 py-5">
                    <PiBookBookmarkThin className="text-white font-extralight sm:text-[35px] text-[25px]" />
                    <h1 className="md:text-[40px]  sm:text-[30px] text-[22px] font-poppins text-white">Books</h1>
                </div>
                <p className="text-white font-poppins font-extralight md:text-[20px] text-sm xl:w-[850px]  lg:w-[750px] md:w-[600px] sm:w-[450px] w-[350px] !leading-relaxed text-center">
                    Textual materials to aid your spiritual growth and biblical education. For personal study and for instructing others.
                </p>
                
                <div className="hidden lg:flex flex-col">
                    <div className="grid grid-cols-3 gap-x-16 mt-8 -mb-4 mx-8">
                         <img src={glossa} alt="" className="" />
                         <img src={tfm} alt="" className="" />
                         <img src={basics} alt="" className="" />
                    </div>
                    <div className="book-line h-2 w-full  z-10 relative" />
                    <div className="grid grid-cols-3 gap-x-16 my-8 mx-8 ">
                         <div className='flex flex-col items-center' >
                            <h1 className="font-semibold text-2xl text-white font-dmsans max-w-72  text-center">Glossa</h1>
                            <p className="font-poppins text-white text-sm font-extralight text-center leading-6 max-w-60"> 
                                God is renewing the earth through tongues and utterances
                            </p>
                         </div>
                         <div className='flex flex-col items-center' >
                            <h1 className="font-semibold text-2xl text-white font-dmsans max-w-72  text-center">The Fisher of Men</h1>
                            <p className="font-poppins text-white text-sm font-extralight text-center leading-6 max-w-60"> 
                                Rediscovering the Heart and Art of Radical Discipleship
                            </p>
                         </div>
                         <div className='flex flex-col items-center' >
                            <h1 className="font-semibold text-2xl text-white font-dmsans max-w-72  text-center">The Basics of the Christian Faith</h1>
                            <p className="font-poppins text-white text-sm font-extralight text-center leading-6 max-w-60"> 
                                An essential Handbook for the New Believer.
                            </p>
                         </div>
                    </div>
                    <div className="flex justify-center items-center">
                        <ButtonX 
                            path='/'
                            text='Get access'
                        />

                    </div>
                </div>
            </div>

            <div className="flex lg:hidden flex-col">
                <BookCarousel />
                <div className="flex justify-center items-center">
                    <ButtonX 
                        path='/'
                        text='Get access'
                    />
                </div>
            </div>

            
        </div>
    )
}

export default RecentBooks