import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";

const UptoDate = () => {
    return (
        <div className="bg-navyBlue flex justify-center py-[8%] px-[10%] text-white">
            <div className="lg:flex w-full gap-y-16">
                <div className="lg:basis-1/2 flex flex-col">
                        <h1 className="font-dmsans xl:text-7xl lg:text-6xl md:text-5xl text-4xl max-w-[500px] xl:tracking-wide ">
                            Stay up to date on the latest
                        </h1>
                    <p className=" max-w-[480px] my-2 font-poppins text-lg font-light">
                        {/* Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sedLorem ipsum dolor sit amet, consectetuer adipiscing elit, sedLorem ipsum dolor sit amet, consectetuer adipiscing elit, sedLorem ipsum dolor sit amet, consectetuer adipiscing elit, sed */}
                    </p>
                </div>
                <div className="lg:basis-1/2 flex flex-col font-poppins flex-wrap lg:content-end ">
                    <p className=" max-w-[400px] mb-8 md:text-lg text-base">
                        For any enquires, questions or comments please fill the following form
                    </p>

                    <input type="text" placeholder='Email address'  className="px-5 py-3 text-black outline-none bg-white rounded-sm lg:w-[400px] w-[300px]"/>

                    <a href="/" className="flex items-center gap-x-2 border border-white w-fit px-5 rounded-md text-lg py-2 my-5 ">
                        <span className="md:text-lg text-base">Submit</span>
                        <IoIosArrowRoundForward  className="text-2xl"/>
                    </a>
                </div>
            </div>
        </div>
    )
}

export default UptoDate