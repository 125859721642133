import React, { useEffect, useState } from 'react';
import ButtonX from '../ButtonX'

const Hero = () => {
    const [isDark, setIsDark] = useState(false);

    useEffect(() => {
        const interval = setInterval(() => {
        setIsDark((prev) => !prev); // Toggle the state every interval
        }, 1000); // Change every second (1000ms)
        
        return () => clearInterval(interval); // Clear interval on unmount
    }, []);

    return (
        <div className="flex flex-col py-[15%] px-[10%] hero-bg justify-center items-center text-white h-[100vh]">
            <h1 className="lg:text-7xl text-5xl max-w-[900px] text-center font-dmsans"> Endless repertoire of sound Bible teachings</h1>
            <p className="lg:text-xl my-7 text-center max-w-[800px] font-poppins">
                Unlock a treasure trove of resources to enrich your faith journey and connect with a vibrant community
            </p>
            <ButtonX 
                text='Get started'
                path='/'
            />
            <a
                href="https://www.didachelibrary.net/auxano/"
                className={`px-8 py-3 my-8 font-bold rounded-xl w-[max-content] font- transition-colors duration-500 ${
                    isDark ? 'bg-[#2eab33] text-white' : 'bg-[#38E440] text-black'
                }`}
                >
                Auxanō Today
            </a>


        </div>
    )
}

export default Hero