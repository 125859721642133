import React from 'react'
import logo from '../images/desk-logo.png';
import { FaXTwitter } from "react-icons/fa6";
import { CiFacebook } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa";
import { MdMailOutline } from "react-icons/md";

const Footer = () => {
    return (
        <div className="bg-navyBlue flex flex-col py-[5%] px-[10%] text-white w-full">
            <div className="flex md:flex-row flex-col w-[-webkit-fill-available]">
                <div className="flex flex-col basis-5/6 w-full">
                    <img src={logo} alt="" className="lg:w-96 md:w-80 sm:w-72 w-56 mb-16" />
                    <div className="md:gap-x-16 gap-x-1  gap-y-4 md:w-96 flex-wrap grid grid-cols-3">
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Updates</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">About</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Blogposts</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Books</a>
                        <a href="https://www.didachelibrary.net/auxano/" className="font-poppins font-extralight w-[fit-content] ">Auxano</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Article</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Sermon</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Newletter</a>
                        <a href="/" className="font-poppins font-extralight w-[fit-content] ">Updates</a>
                    </div>
                </div>
                <div className="flex flex-col basis-2/6 w-full">
                    <h1 className="font-dmsans md:text-3xl text-2xl md:my-12 my-7 ">Contact</h1>
                    <div className="flex gap-x-6 md:mt-5 items-center">
                        <a href="/"> <MdMailOutline className="text-2xl" /></a>
                        <a href="https://twitter.com/theWhitefield_m"> <FaXTwitter className="text-2xl" /></a>
                        <a href="https://web.facebook.com/thewhitefieldmissions"> <CiFacebook className="text-3xl" /></a>
                        <a href="https://www.instagram.com/thewhitefieldmissions"> <FaInstagram className="text-2xl" /></a>
                    </div>
                </div>
            </div>
            <div className="border-b border-white w-full mt-16 mb-4" />
            <p className="text-grayX font-poppins text-sm">This site is protected by reCAPTCHA and the Google Privacy Policy</p>
        </div>
    )
}

export default Footer