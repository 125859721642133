import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/AudioTeachings/Hero'
import Footer from '../components/Footer'
import AudioDiv from '../components/AudioTeachings/AudioDiv'
import Enquire from '../components/AudioTeachings/Enquire'

const AudioTeachings = () => {
    return (
        <div className="bg-navyBlue !overflow-x-hidden">
            <Navbar 
                pageTitle='Audio Messages'
            />
            <Hero />
            <AudioDiv />
            <Enquire />
            <Footer />
        </div>
    )
}

export default AudioTeachings