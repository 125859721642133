import React from 'react'
import nbPic from '../../images/nb-webpic.png'
import ButtonX from '../ButtonX'

const NewBrook = () => {
    return (
        <div className="bg-navyBlue flex justify-center py-[10%] px-[8%] text-white">
            <div className="lg:flex w-full gap-y-6">
                <div className="lg:basis-1/2 flex flex-col justify-center items-center"> 
                    <div >
                        <h1 className="font-dmsans lg:text-7xl md:text-6xl sm:text-5xl text-4xl max-w-[500px] tracking-wide ">
                            The NewBrook
                            Church
                        </h1>
                        <p className=" max-w-[500px] my-12 font-poppins md:text-lg text-md font-light">
                            We have drunk of the fountain of life that Jesus is—He is to us our <span className="font-semibold italic">identity</span> and purpose. We are restoring life and hope to every people, filling hearts with joy resounding
                        </p>
                        <div className="lg:flex hidden justify-center">
                            <ButtonX 
                                path='https://www.thenewbrook.com/'
                                text='Visit Website'
                            />
                        </div>
                    </div>
                </div>
                <div className="lg:basis-1/2 flex flex-col items-center lg:mt-0 mt-4 gap-y-16">
                    <img src={nbPic} alt="" className="w-[80%]" />
                    <div className="flex justify-center lg:hidden ">
                            <ButtonX 
                                path='https://www.thenewbrook.com/'
                                text='Visit Website'
                            />
                        </div>
                </div>

            </div>
        </div>
    )
}

export default NewBrook