import React from 'react'
import Navbar from '../components/Navbar'
import Hero from '../components/Home/Hero'
import RecentAudio from '../components/Home/RecentAudio'
import RecentVideos from '../components/Home/RecentVideos'
import RecentBooks from '../components/Home/RecentBooks'
import Devotion from '../components/Home/Devotion'
import UptoDate from '../components/Home/UptoDate'
import Footer from '../components/Footer'
import BlogSection from '../components/Home/BlogSection'
import NewBrook from '../components/Home/NewBrook'


const Home = () => {
    return (
        <>
            <Navbar search/>
            <Hero />
            {/* <Devotion /> */}
            <RecentAudio />
            <RecentVideos />
            <RecentBooks />
            <BlogSection />
            <NewBrook />
            <UptoDate/>
            <Footer />
        </>
    )
}

export default Home