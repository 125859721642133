import React from 'react'
import { IoIosArrowRoundForward } from "react-icons/io";

const Enquire = () => {
    return (
        <div className="bg-navyBlue flex flex-col items-center justify-center py-[8%] px-[10%] text-white">
            <p className=" max-w-[400px] mb-8 md:text-lg text-base text-center">
                 For any enquires, questions or comments please fill the following form
            </p>

            <input type="text" placeholder='Email address'  className="px-5 py-3 text-black outline-none bg-white rounded-sm sm:w-[400px]"/>

            <a href="/" className="flex items-center gap-x-2 border border-white w-fit px-5 rounded-md text-lg py-2 my-5 ">
                <span className="md:text-lg text-base">Submit</span>
                <IoIosArrowRoundForward  className="text-2xl"/>
            </a>
        </div>
    )
}

export default Enquire