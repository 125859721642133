import tfm from '../images/BOOK DESIGN FISH.png'
import glossa from '../images/BOOK DESIGN glosa b.png'
import basics from '../images/BOOK DESIN basics.png'

const books = [
    {
        pic: glossa,
        title: "Glossa",
        desc: "God is renewing the earth through tongues and utterances"
    },
    {
        pic: tfm,
        title: "The Fisher of Men",
        desc: "Rediscovering the Heart and Art of Radical Discipleship."
    },
    {
        pic: basics,
        title: "The Basics of the Christian Faith",
        desc: "An essential Handbook for the New Believer."
    },
    // {
    //     pic: bookImg,
    //     title: "The Basics of the Christian Faith",
    //     desc: "Every standing man began with baby steps. The new believer is also as a new born, and requires gentle introduction into the knowledge of God's Word. This material is written to aid young Christians in their establishment and growth in the doctrine of the scriptures. A great teaching tool for Pastors, leaders, and disciplers"
    // },
    // {
    //     pic: bookImg,
    //     title: "God is not mat at you; He is crazy about you",
    //     desc: "God is Crazy About You was written to convey God's love and purpose to young hearts. We believe that it is possible for children and teenagers to discover and walk in the purpose of God like Samuel and Daniel. This book takes the child through a journey of discovery and learning. Bible truths are conveyed in child-like simplicity and the gap between God's Word and the child is bridged."
    // },
    // {
    //     pic: bookImg,
    //     title: "The Metamorphoō Manual",
    //     desc: "One of our early contributions to the spiritual education of children and teenagers. This is a great tool for children teachers and handlers towards the discipleship of young folks. For use in Bible Clubs, Sunday Schools, secondary and primary schools, and anywhere else children are found."
    // },
]

export default books