import React,{ useState } from 'react'
import deskLogo from'../images/desk-logo.png'
import { IoSearchOutline } from "react-icons/io5";
import { IoIosArrowDown, IoIosArrowUp, IoMdClose } from "react-icons/io";
import { HambergerMenu } from 'iconsax-react';
import { Link } from 'react-router-dom';
import { HashLink } from "react-router-hash-link";

interface NavProps{
    search?: boolean,
    pageTitle?: string,
}

const Navbar: React.FC<NavProps> = ({ search, pageTitle }) => {
    const [toggleOpen, setIsOpen] = useState(false);
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const closeSidebar = () => {
        setSidebarOpen(false)
    }

    const openSidebar = () => {
        setSidebarOpen(true)
    }

    const toggleDropdown = () => {
        setIsOpen(!toggleOpen);
    };


    return (
        <div className="flex fixed w-full items-start top-0 left-0 bg-white bg-opacity-10 !z-50 backdrop-blur-lg backdrop-saturate-150 shadow-md">
            <div className="flex xl:px-[20%] lg:px-[15%] md:px-[10%] px-[8%] py-7  w-full items-center justify-between ">
            
                <Link to='/'>
                    <img src={deskLogo} alt="" className="md:w-36 w-24 flex " />
                </Link>
                {
                    search && (
                        <div>
                            <div className="md:flex hidden items-center gap-x-2 border border-white sm:w-full w-[200px]  rounded-full px-4 py-1">
                                <IoSearchOutline className="text-xl text-white"/>
                                <input type="text" placeholder='search' className=" bg-transparent outline-none text-white caret-color "/>
                            </div>
                        </div>

                    )
                }
                {
                    pageTitle && (
                        <div>
                            <div className="md:flex hidden text-center border border-white lg:text-2xl md:text-xl font-dmsans text-white sm:w-full w-[200px]  rounded-md md:px-12 px-6 py-3">
                                {pageTitle}
                            </div>
                        </div>
                    )
                }
                <div className="p-1 rounded-full border-2" onClick={openSidebar}>
                    <HambergerMenu size="24" color="#ffffff"/ >  

                </div>
                
            </div>
            { sidebarOpen && (
                <div className="flex flex-col py-10 px-12 h-[100vh] absolute right-0 bg-darkGray md:w-[400px] sm:w-[300px] w-[full] text-white">

                    <div className="py-8 flex justify-end ">
                            <IoMdClose className="text-2xl" onClick={closeSidebar}/>
                    </div>
                    <div className="flex justify-center flex-col gap-y-10">
                        <Link to='/' className="font-dmsans lg:text-[24px] md:text-[20px] sm:text-base xs:text-sm !font-light">Home</Link>
                        <div className="font-dmsans lg:text-[24px] md:text-[20px] sm:text-base xs:text-sm !font-light flex items-center gap-x-2 cursor-pointer" onClick={toggleDropdown}>
                            <span>Categories</span>
                            {toggleOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </div>
                        {toggleOpen && (
                            <div className="pl-6 flex flex-col gap-y-4 border-l transition-all duration-300 ease-in-out">
                                <HashLink smooth to='/#books' className="font-dmsans md:text-xl sm:text-sm text-xs !font-extralight">Books</HashLink>
                                <Link to='/audio-teachings' className="font-dmsans md:text-xl sm:text-sm text-xs  !font-extralight">Sermons</Link>
                                <a href='https://www.youtube.com/@thenewbrook' className="font-dmsans md:text-xl sm:text-sm text-xs  !font-extralight">Videos</a>
                            </div>
                        )}
                        <Link to='/' className="font-dmsans lg:text-[24px] md:text-[20px] sm:text-base xs:text-sm !font-light">About</Link>
                        <Link to='/' className="font-dmsans lg:text-[24px] md:text-[20px] sm:text-base xs:text-sm !font-light">Contact</Link>
                        <a href='https://www.didachelibrary.net/auxano/' className="font-dmsans lg:text-[24px] md:text-[20px] sm:text-base xs:text-sm !font-light">Auxano</a>
                        <a href='https://www.thenewbrook.com/' className="font-dmsans lg:text-[24px] md:text-[20px] sm:text-base xs:text-sm !font-light">The NewBrook Church</a>
                    </div>
                </div>

            )}
        </div>
    ) 
}

export default Navbar