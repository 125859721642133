import pic from '../images/BORN FOR A PURPOSE.png'
import pic2 from '../images/Frame 220.png'
import pic3 from '../images/Frame 223.png'
import pic29 from '../images/Frame 224.png'
import pic4 from '../images/Frame 226.png'
import pic5 from '../images/Frame 227.png'
import pic6 from '../images/Frame 232.png'
import pic7 from '../images/Frame 220 (1).png'
import pic8 from '../images/DEPTHS OF GOD.png'
import pic9 from '../images/WHAT TO LIVE FOR 1.png'
import pic10 from '../images/DON’T FORGET PURPOSE.png'
import pic11 from '../images/GOD REMEMBERS.png'
import pic12 from '../images/In Christ Our Union.png'
import pic13 from '../images/Why We Do What We Do.png'
import pic14 from '../images/Investigating God’s Character.png'
import pic15 from '../images/KABAS SUBDUE.png'
import pic16 from '../images/Ministry Of the Word.png'
import pic17 from '../images/Our Supernatural HERITAGE.png'
import pic18 from '../images/RESPONSIBILITY & F0CUS.png'
import pic19 from '../images/RISK It ALL.png'
import pic20 from '../images/SHEPHERD OF ISRAEL.png'
import pic21 from '../images/The Supernatural Place.png'
import pic22 from '../images/The Supernatural beings.png'
import pic23 from '../images/The name of Jesus.png'
import pic24 from '../images/Understanding the COMMUNITY.png'
import pic25 from '../images/Do not Boast.jpg'
import pic26 from '../images/SWC.jpg'
import pic27 from '../images/MLR.jpg'
import pic28 from '../images/awt.png'
// import pic30 from '../images/CTC.jpg'
// import pic31 from '../images/Following Jesus.jpg'
import pic0 from '../images/Redeeming The Times.jpg'
import audio1 from '../assets/aud1.mp3' 
import audio2 from '../assets/aud2.mp3' 
import audio3 from '../assets/aud3.mp3' 
import audio4 from '../assets/aud4.mp3' 
const messages = [
    {
        img: pic0,
        title: 'REDEEMING THE TIMES',
        audioSrc: [
            '/RTT/REDEEMING THE TIMES I',
            '/RTT/REDEEMING THE TIMES II',
        ],
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic25,
        audioSrc: '/DO NOT BOAST, RATHER PRAY (KATARTISMOS CONGRESS 2023).mp3',
        title: 'Do not Boast, Rather Pray',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic26,
        audioSrc: [
            '/SWC2024/SOUL WINNERS CONFERENCE (T1).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (WHAT WILL YOU DO WITH THE HOLY SPIRIT) (T2).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (T3).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (T4).mp3',
            '/SWC2024/SOUL WINNERS CONFERENCE (T8).mp3',
        ],
        title: 'Soul Winners Conference 2024',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic28,
        audioSrc: [
            '/awt/As We Are Taught 1 (Readiness to Defend) January 12, 2022.mp3',
            '/awt/As We Are Taught 2 (Knowledge and Appraisal) January 26, 2022.mp3',
            '/awt/As We Are Taught 3 (Reteaching the Church in Exile) January 30, 2022.mp3',
        ],
        title: 'As We Are Taught',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic27,
        audioSrc: [
            "/MLR2024/MLR '24, TRACK 1.mp3",
            "/MLR2024/MLR '24, TRACK 2.mp3",
            "/MLR2024/MLR '24, TRACK 3.mp3",
            "/MLR2024/MLR '24, TRACK 4.mp3",
            "/MLR2024/MLR '24, TRACK 5.mp3",
            "/MLR2024/MLR '24, TRACK 6.mp3",
            "/MLR2024/MLR '24, TRACK 8 (LOOK NOT AT THYSELF).mp3",
            "/MLR2024/MLR '24, TRACK 9 (SUNDAY MORNING).mp3",
            "/MLR2024/MLR '24, TRACK 10 (CLOSING CHARGE).mp3",
        ],
        title: "Ministers' & Leaders' Retreat 2024",
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic29,
        audioSrc: [
            '/COG/CLOUD OF GLORY, TRACK 1, NOVEMBER 2022.mp3',
            '/COG/CLOUD OF GLORY, DAY TWO (2), NOVEMBER 2022.mp3',
            '/COG/CLOUD OF GLORY, DAY THREE, NOVEMBER 2022.mp3',
        ],
        title: 'Cloud of Glory',
        artist: 'Pastor Fred Adekunle'
    },
    // {
    //     img: pic30,
    //     audioSrc: '',
    //     title: 'Counting the Cost',
    //     artist: 'Pastor Fred Adekunle'
    // },
    {
        img: pic3,
        audioSrc: [
            "/COTW/Culture of the Word Track 1 (Basics Series) July 5, 2020.mp3",
            "/COTW/Culture of the Word Track 2 (Basics Series) July 8, 2020.mp3",
            "/COTW/Culture of the Word Track 3 Parable (Basic Series) July 15, 2020.mp3",
            "COTW/Culture of the Word Track 4 (Basics Series) August 5, 2020.mp3",
        ],
        title: 'Culture of Word',
        artist: 'Pastor Fred Adekunle'
    },
    // {
    //     img: pic31,
    //     audioSrc: '',
    //     title: 'Following Jesus',
    //     artist: 'Pastor Fred Adekunle'
    // },
    {
        img: pic2,
        audioSrc: '',
        title: 'Forming Habits and Building Character',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic7,
        audioSrc: [
            '/DOP/Dynamics of Prayer (2022) Track 1.mp3',
            '/DOP/Dynamics of Prayer (2022) Track 2.mp3',
            '/DOP/Dynamics of Prayer 3 March 2.mp3',
            '/DOP/The Dynamics Of Prayer 4 Mar 6 2022.mp3',
        ],
        title: 'The Dynamics of Prayer',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic4,
        audioSrc: '',
        title: 'Revive your Work',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic5,
        audioSrc: '',
        title: 'Spiritual Intelligence',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic6,
        audioSrc: '',
        title: 'Bold Confessios of Faith',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic8,
        audioSrc: '',
        title: 'Depths of God',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic9,
        audioSrc: '',
        title: 'What to Live For',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic10,
        audioSrc: '',
        title: "Don't Forget Purpose",
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic11,
        audioSrc: '',
        title: 'GOD REMEMBERS',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic12,
        audioSrc: '',
        title: 'In Christ Our Union',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic13,
        audioSrc: '',
        title: 'Why We Do What We Do',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic14,
        audioSrc: '',
        title: 'Investigating God’s Character',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic15,
        audioSrc: '',
        title: 'KABAS SUBDUE',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic16,
        audioSrc: '',
        title: 'Ministry Of the Word',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic17,
        audioSrc: '',
        title: 'Our Supernatural Heritage',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic18,
        audioSrc: '',
        title: 'Responsibility and Focus',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic19,
        audioSrc: '',
        title: 'Risk it All',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic20,
        audioSrc: '',
        title: 'Gifts of the Spirit',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic21,
        audioSrc: '',
        title: 'The Supernatural Place',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic22,
        audioSrc: '',
        title: 'The Supernatural Beings',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic23,
        audioSrc: '',
        title: 'The name of Jesus',
        artist: 'Pastor Fred Adekunle'
    },
    {
        img: pic24,
        audioSrc: '',
        title: 'Understanding the Community',
        artist: 'Pastor Fred Adekunle'
    },
]

export default messages