import React, {useState, useEffect} from 'react'
import { useAudio } from '../../utils/AudioContext';
import { Previous, Next, PlayCircle, PauseCircle } from 'iconsax-react';

const AudioStrip = () => {
	const {currentTrack, togglePlayPause, handlePrevTrack, handleNextTrack, isPlaying, } = useAudio(); // Get global state	
	console.log(currentTrack);
	
	return (
		<div className={`w-full fixed bottom-0 py-4 px-24 gap-x-6 items-center bg-black text-white font-poppins ${currentTrack? 'hidden': 'hidden' } `}>
			<div className="flex items-center gap-x-4 w-[700px]">
				<img src={currentTrack?.img} alt={currentTrack?.title} className="h-20 rounded-lg" />
				<div className="flex flex-col gap-y-1">
					<h2 className="text-lg font-semibold">{currentTrack?.title}</h2>
					<span className={`text-sm ${currentTrack?.isSingleAudio ? 'hidden': 'flex'}`}>Track {currentTrack?.currentTrackIndex +1 } </span>
					<span className="text-sm">{currentTrack?.artist} </span>
				</div>
			</div>
			<div className="flex flex-col justify-center items-center w-full ">
				<div className="flex justify-center items-center gap-x-8">
					<button onClick={handlePrevTrack} className="px-4 rounded-md" title="Previous Track">
						<Previous size="24" color="#ffffff" />
					</button>
					<button onClick={togglePlayPause} className="px-4 rounded-md" title={isPlaying ? "Pause" : "Play"}>
						{isPlaying ? <PauseCircle size="32" color="#ffffff" variant="Bold" /> : <PlayCircle size="32" color="#ffffff" variant="Bold" />}
					</button>
					<button onClick={handleNextTrack} className="px-4 rounded-md" title="Next Track">
						<Next size="24" color="#ffffff" />
					</button>
				</div>
				<div className="w-full px-4">
					<input type="range" className="audio-progress w-[60%] mt-3" min="0" max="100" />
				</div>
			</div>
		</div>
	);
};

export default AudioStrip