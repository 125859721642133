import React from 'react'


const Hero = () => {
    return (
        <div className="flex justify-center items-center bg-navyBlue text-white md:pt-0 pt-36 py-[8%]">
            <div className="flex md:hidden text-center border border-white sm:text-2xl text-xl font-dmsans text-white  rounded-md md:px-12 px-6 py-3">
                Audio Messages
            </div>
        </div>
    )
}

export default Hero