import React from 'react'
import { Link } from 'react-router-dom'
import { GoArrowUpRight } from "react-icons/go";

interface buttonProp {
    path: string,
    text: string,
    textSize?: string,
    arrow?: boolean,
}

const ButtonX = (props: buttonProp) => {
    const {path, text, arrow,textSize} = props;
    return (
        <div>
            <Link to={path} className={`flex items-end text-white text-xl rounded-full px-12 py-2 border font-dmsans border-white ${textSize? textSize : ''} `}>
                <span className="md:text-lg text-base"> {text}</span> 
                {
                    arrow &&
                    <GoArrowUpRight className="ml-1 text-2xl"/>
                }
            </Link>
        </div>
    )
}

export default ButtonX