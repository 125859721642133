import React from 'react'
import ButtonX from '../ButtonX'
import blogPic from '../../images/humble-lamb-C8c6ecf7t9o-unsplash 1.png'

const BlogSection = () => {
    return (
        <div className=" bg-blueX flex justify-center items-center py-[5%] min-h-[100vh] text-white">
            <div className="grid lg:grid-cols-2 gap-y-16 justify-center px-[10%] gap-x-9">
                <div>
                    <h1 className="lg:text-6xl sm:text-5xl text-4xl my-5 font-dmsans">
                        What is the <br/>
                        <span className="text-greenX">Bible</span> about?
                    </h1>
                    <p className="md:text-lg sm:text-base text-sm my-6 font-poppins max-w-[500px]">
                        The Bible is incontestably the greatest book of all time. It is currently the most printed, most cited, most read, and most powerful book that this world has ever seen. Many people have died for it; many have lived defending its contents...
                    </p>
                    <div className="flex justify-center">
                        <ButtonX 
                            text='Read more'
                            path='/'
                        />
                    </div>
                </div>
                <div className=" lg:block">
                    <img src={blogPic} alt="" className="lg:w-full" />
                </div>
            </div>
        </div>
    )
}

export default BlogSection