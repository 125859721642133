import React, { Component } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import './Carousel.scss'

interface CarouselProps {
	items: string[]; // Array of imported image modules
	active: number;
}

interface CarouselState {
	items: string[];
	active: number;
	direction: string;
}

class Carousel extends Component<CarouselProps, CarouselState> {
	constructor(props: CarouselProps) {
		super(props);
		this.state = {
			items: this.props.items,
			active: this.props.active,
			direction: ''
		};
	}

	generateItems = () => {
		const items = [];
		let level;
		for (let i = this.state.active - 2; i < this.state.active + 3; i++) {
			let index = i;
			if (i < 0) {
				index = this.state.items.length + i;
			} else if (i >= this.state.items.length) {
				index = i % this.state.items.length;
			}
			level = this.state.active - i;
			items.push(
				<CSSTransition key={index} timeout={500} classNames={this.state.direction}>
					<Item src={this.state.items[index]} level={level} />
				</CSSTransition>
			);
		}
		return items;
	}

	moveLeft = () => {
		let newActive = this.state.active;
		newActive--;
		this.setState({
			active: newActive < 0 ? this.state.items.length - 1 : newActive,
			direction: 'left'
		});
	}

	moveRight = () => {
		let newActive = this.state.active;
		this.setState({
			active: (newActive + 1) % this.state.items.length,
			direction: 'right'
		});
	}

	render() {
		return (
			<div id="carousel" className="noselect">
				<div className="arrow arrow-left" onClick={this.moveLeft}>
					<MdKeyboardArrowLeft className="text-7xl w-11  bg-gray-500 h-16 font-light rounded-xl py-2 border border-white" />
				</div>
				
				<TransitionGroup>
					{this.generateItems()}
				</TransitionGroup>
				<div className="arrow arrow-right" onClick={this.moveRight}>
					<MdKeyboardArrowRight className="text-7xl w-11 bg-gray-500 h-16 font-light rounded-xl py-2 border border-white" />
				</div>
			</div>
		);
	}
}

interface ItemProps {
	src: string; // Image module
	level: number;
}

const Item: React.FC<ItemProps> = ({ src, level }) => {
	const className = `item level${level}`;
	return (
		<div className={className}>
			<img src={src} alt={`Carousel item ${level}`} className="!w-72 rounded-3xl "/>
		</div>
	);
};

export default Carousel;
