import React from 'react'
import { PiVideoCameraLight } from "react-icons/pi";
import ButtonX from '../ButtonX'
import vidPic from '../../images/Frame 199.png'
import { motion } from "framer-motion";

const RecentVideos = () => {
    return (
        <div className="rv-bg flex flex-col md:py-[4%]  py-[40px]">
            {/* <div className="flex flex-col items-center">
                
                <div className="film-bg flex flex-wrap w-full h-[60vh]  my-14">
                    <img src={vidPic} alt="" className="w-[700px]" /> 
                </div>

                <div className="py-8">
                    <ButtonX 
                        text='More'
                        path='https://www.youtube.com/@thenewbrook'
                        arrow={true}
                    />
                </div>

            </div> */}
  
            <div className="flex flex-col  justify-center items-center w-full">
                <div className="flex sm:flex-row flex-col justify-center items-center md:gap-x-4 gap-x-1 py-5 ">
                    <PiVideoCameraLight className="text-white md:text-[40px] sm:text-[35px] text-[25px]" />
                    <h1 className="md:text-[40px]  sm:text-[30px]  text-[22px] font-poppins text-white ">Videos</h1>
                </div>
                <p className="text-white font-poppins font-extralight md:text-[20px] text-sm xl:w-[850px]  lg:w-[750px] md:w-[600px] sm:w-[450px] w-[350px] !leading-relaxed text-center">
                    Watch our latest video teachings that provide insight and inspiration for your spiritual journey.
                </p>
            </div>

            <div className="film-bg flex justify-center items-center  md:py-10 py-7 w-full">
                <a href="https://www.youtube.com/watch?v=vPdjoz77kos">
                    <motion.div
                        className="box"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 10 }}
                    >
                        <img src={vidPic} alt="" className="xl:w-[800px] lg:w-[700px] md:w-[550px] sm:w-[400px] w-[250px] " /> 
                    </motion.div>
                </a>
            </div>

            <div className="flex flex-col md:gap-y-10 gap-y-5 items-center justify-center">
                <h1 className='md:text-[32px] text-lg font-poppins text-white font-normal'>
                    God keep me in perfect peace.
                </h1>
                <ButtonX 
                    text='More'
                    path='https://www.youtube.com/@thenewbrook'

                />
            </div>
        </div>
    )
}

export default RecentVideos