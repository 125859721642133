import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { RxCaretLeft, RxCaretRight } from "react-icons/rx";
import BookCard from './BookCard'
import books from '../../utils/Books'

function SampleNextArrow(props: any) { 
  const { className, currentSlide, slideCount, onClick, slidesToShow } = props;
  console.log(slideCount, currentSlide, slidesToShow);
  
  return (
    <div onClick={onClick} >
        <button onClick={onClick} className={className} >
            <RxCaretRight className={`text-6xl text-white absolute bottom-[70px] z-50 sm:right-[1px] right-[25px] ${currentSlide >= slideCount - slidesToShow ? 'text-black cursor-not-allowed' : ''}`}
                onClick={onClick}
                
            />
        </button>
    </div>
  ); 
}

function SamplePrevArrow(props: any) {
  const { className, currentSlide, onClick } = props;
  return (
    <div onClick={onClick}>
        <button onClick={onClick} className={className} >
            <RxCaretLeft className={`text-6xl text-white absolute bottom-[70px] z-50 sm:left-[-40px] left-[29px] ${currentSlide === 0 ? 'text-black cursor-not-allowed' : ''}`}
                onClick={onClick}
            />
        </button>
    </div> 
  );
}

const BookCarousel = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 2000, 
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow slidesToShow={1} />,
    prevArrow: <SamplePrevArrow  />
    
  };
    return (
        <div className="md:w-[500px] w-[350px]  my-10">
            <div className="slider-container">
                <Slider {...settings}>
                    {
                        books.map((book, index) =>(
                            <BookCard 
                                key={index}
                                pic={book.pic}
                                title={book.title}
                                desc={book.desc}
                            />
                        ))
                    }
                </Slider>
            </div>
        </div>
    )
}

export default BookCarousel