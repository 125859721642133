import React from 'react'
import ButtonX from '../ButtonX'


interface bookProp{
    pic: string,
    title: string,
    desc: string,
    trunc?: boolean
}

const BookCard = (props:bookProp) => {
    const {pic, title,desc, trunc} = props
    return (
        <div className="flex flex-col gap-y-4 items-center w-60 text-white">
            <img src={pic} alt="" className="" />
            <h1 className="font-semibold text-2xl text-white font-dmsans max-w-72  text-center">{title}</h1>
            <p className={`font-poppins text-sm font-extralight text-center leading-6 max-w-60 `}>  {desc} </p>
            
        </div>
    )
 }

export default BookCard