import React from "react";
import Home from "../pages/Home";
import AudioTeachings from "../pages/AudioTeachings";


const routes =[
    {
        path: '/',
        element: <Home />
    },
    {
        path: '/audio-teachings',
        element: <AudioTeachings />
    },
]

export default routes